<template>
  <div>
    <ed-documento 
      :intCadastroGeralEmpresaId="Number($route.params.intCadastroGeralEmpresaId)" 
      :intCadastroGeralUnidadeId="Number($route.params.intCadastroGeralUnidadeId)"
      :intTipoDocumentoId="280" :intTipoRevisaoId="285" :key="$root.$session.versao"/>
  </div>
</template>

<script>
import EdDocumento from "@/components/documento/editar";
export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: {EdDocumento },
  mounted() {
    if(!this.$route.params.intCadastroGeralEmpresaId){
      this.$router.push({ name: "pgr" });
    }
  },
  created() {},
  data() {
    return {
    };
  },
  methods: {
  
  },
};
</script>